import React, {
  DragEventHandler,
  ChangeEventHandler,
  useState,
  FC,
  memo,
} from 'react';
import Icon from '$components/icons/icon/icon.react';
import './file-upload.css';
import { mutate } from '$lib/hooks/fetch-utillities';
import { useIsMobile } from '$lib/hooks/isMobile';
import {
  UploadScriptConfigDocument,
} from '$typings/graphql-codegen';
import ErrorText from '$components/texts/error-text/error-text.react';

const FileUploadComponent: FC = () => {
  const isMobile = useIsMobile();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleFileList = async (files: FileList) => {
    if (uploadError) setUploadError(false);
    setUploadErrorMessage("")
    setUploading(true);    
    for (let i = 0, f; (f = files[i]); i++) {
      const text = await f.text()
      await mutate(
        UploadScriptConfigDocument,
        { 
          fileName: f.name,
          jsonString : text 
        },
        true,
        ({ uploadScriptConfig: { message, success } }) => {
          if (!success) {
            setUploadError(true)
            setUploadErrorMessage(message)
          }
          setUploading(false);
        },
        () => {
          setUploadError(true)
          setUploading(false);
        }
      );        
    }
  };

  const selectFile: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.files) {
      await handleFileList(e.target.files);
    }
  };

  const dragLeave: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isDraggingOver) {
      setIsDraggingOver(false);
    }
  };

  const onDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
    if (e.dataTransfer) {
      await handleFileList(e.dataTransfer.files);
    }
  };

  const dragOver: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDraggingOver) setIsDraggingOver(true);
    if (uploadError) setUploadError(false);
  };

  return (
    <div
      className={`droppable-area ${isDraggingOver ? 'dragover' : ''}`}
      onDragOver={dragOver}
    >
      <ErrorText className={"center-content mar_rm"}>{uploadErrorMessage}</ErrorText>
      <div
        className="droppable-area-eventcatcher"
        onDragLeave={dragLeave}
        onDrop={onDrop}
      />
      <div
        className={`droppable-area-cloud-icon ${uploadError ? 'error' : ''}`}
      >
        {uploading ? (
          <Icon name={'fa-spinner fa-pulse'} />
        ) : uploadError ? (
          <Icon name={'fa-exclamation-triangle'} />
        ) : (
          <Icon name="fa-cloud-upload" />
        )}
      </div>

      {!isMobile && (
        <div className="droppable-area-main-text">
          {isDraggingOver && !uploading && (
            <span>Drop the file here</span>
          )}
          {!isDraggingOver && !uploading && !uploadError && (
            <span>Drag a script config file here</span>
          )}
          {uploading && (
            <span>Persisting the script config file</span>
          )}
          {!isDraggingOver && uploadError && (
            <span>Could not persist file. Try again</span>
          )}
        </div>
      )}

      <span
        className={`droppable-area-sub-text ${uploading ? 'transparent' : ''}`}
      >
        {!isMobile && 'or'}

        <div className="droppable-area-sub-filelink-wrapper">
          <span className="droppable-area-sub-filelink">
            {
              isMobile
                ? 'Select a file from your device'
                : 'Select a file from your computer'
            }
          </span>
          <input
            type="file"
            accept=".json"
            id="files"
            name="files[]"
            className="droppable-area-sub-filelink-picker"
            onChange={selectFile}
          />
        </div>
      </span>      
    </div>
  );
};

export const FileUpload = memo(FileUploadComponent);
