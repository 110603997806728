import { FileUpload } from './modules/file-upload';
import { default as React, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { mutate, useQuery } from '$lib/hooks/fetch-utillities';
import {
  DeleteScriptConfigDocument,
  ScriptConfigsDocument,
} from '$typings/graphql-codegen';
import { pureFormatDate } from '$lib/dateHelpers';
import { Button, Card } from '@mui/material';
import { downloadFileFromUrl } from '$lib/hooks/blob-utillities';
import { getUserFeatures } from '../../config/sessionService';
import RequireConfirmation from '$components/require-confirmation/require-confirmation.react';
import Icon from '$components/icons/icon/icon.react';

const ScriptConfigs = () => {
  const [scriptConfigIdToDelete, setScriptConfigIdToDelete] = useState<Number | null>(null);

  const { data } = useQuery(ScriptConfigsDocument, {
  });

  const features = getUserFeatures();

  const deleteOnCancel = () => {
    setScriptConfigIdToDelete(null)
  }

  const deleteOnConfirm = async () => {
    const scriptConfigId = scriptConfigIdToDelete as Number // we are sure it is not null here because that is the requirement for showing the RequireConfirmation dialog
    await mutate(
      DeleteScriptConfigDocument,
      { scriptConfigId },
      true,
      undefined,
      undefined
    );
    setScriptConfigIdToDelete(null)    
  }

  const tableDeleteButtonClick = (scriptConfigId: Number) => {
    setScriptConfigIdToDelete(scriptConfigId)
  }

  const tableHistoryButtonClick = (scriptConfigId: Number) => {
    const historyUrl = `/scriptconfighistory/${scriptConfigId}`
    window.location.href = historyUrl;
  }

  return (
    <Card sx={{margin: "1rem", padding: "1rem", maxWidth:"800px"}}>
      <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Name</TableCell>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Modified</TableCell>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Download</TableCell>
                <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>History</TableCell>
                {features.scriptConfigs.delete && (
                  <TableCell sx={{backgroundColor:'#3c8dbc',color:'#ffffff'}}>Delete</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.scriptConfigs.map((row) => (
                  <TableRow key={row.scriptConfigId}>
                    <TableCell>{row.scriptConfigName}</TableCell>
                    <TableCell>{pureFormatDate(row.lastConfigChanged, true, '.')}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() => downloadFileFromUrl(row.readUrl, row.fileName)}
                      >
                        <Icon name={'fa-download'} />
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                          variant="outlined"
                          onClick={() => tableHistoryButtonClick(row.scriptConfigId)}
                          >
                          <Icon name={'fa-history'} />
                      </Button>
                    </TableCell>
                    {features.scriptConfigs.delete && (
                      <TableCell>                      
                        <Button
                          variant="outlined"
                          onClick={() => tableDeleteButtonClick(row.scriptConfigId)}
                          >
                          <Icon name={'fa-trash'} />
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                ))} 
            </TableBody>
          </Table>
        </TableContainer>
        {data?.scriptConfigs.length == 0 && (
          <div style={{margin: "1rem", textAlign:"center"}}>
            <span>No script configs available (yet)</span>
          </div>
        )}
        {scriptConfigIdToDelete != null && (
          <RequireConfirmation onConfirm={deleteOnConfirm} onCancel={deleteOnCancel}>
              <span>Are you sure you want to delete this script config and all of its history ?</span>
          </RequireConfirmation>
        )}
        {features.scriptConfigs.write && (
          <FileUpload/>
        )}
    </Card>
  );
};

export default ScriptConfigs;
